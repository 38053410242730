@import "../../styles/colors";

.navbar {
  padding: 0 0 0 0;

  .nav-link.menu-item {
    &:link,
    &:visited,
    &:hover {
      color: $grey;
    }

    &.active {
      color: $primary !important;
    }

    &:hover {
      text-decoration: underline;
    }

    padding: 8px 16px;
  }
}