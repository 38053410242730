@import "colors";

.services-table {
    thead {
        vertical-align: middle;
        background-color: $lightbg;
        height: 40px;

        th {
            font-weight: 700;
            color: $grey;
        }
    }

    tbody.services-table-body {
        border-top: 0;
    }

    th, td {
        text-align: left;
        padding-left: 5vw;

        &:last-child {
            text-align: right;
        }
    }

    .form-check-input:checked {
        background-color: $accent;
        border-color: $accent;
    }
}

.table > :not(:first-child) {
    border-top-width: 0 !important;
}

.data-table {
    width: 100%;
    table-layout: auto;

    tr {
        width: 100%;

        th {
            background-color: $lightbg;
            padding: 10px 12px;
        }

        td {
            padding: 12px 12px;
            border-bottom: 1px solid $lightbg;
            &.icon {
                width: 10%;
            }
            &.text {
                width: 30%;
            }
        }
    }
}