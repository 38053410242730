@import "./colors.scss";

.title-row {
  align-content: center;
  height: 100%;

  h1 {
    font-size: 50px;
    font-weight: 400;
  }
}

.sub-heading {
  margin: auto 0;
  font-size: 2rem;
  line-height: 2rem;
}

.expand-btn {
  width: max-content;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  > img:nth-child(2) {
    position: absolute;
  }
}

.input {
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid black;
  box-shadow: none;
  background-color: $lightbg;
  font-size: 0.875rem;

  &:focus {
    box-shadow: none !important;
    background-color: #f3f3f3;
    border-bottom-color: black;
  }
}

.form {
  label {
    font-size: 0.75rem;
    height: 54px;
    display: flex;
    align-items: flex-end;
  }

  input {
    @extend .input;
    height: 42px;
  }

  .select {
    [class*="input-container"],
    [class*="value-container"],
    [class*="indicators-container"] {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    [class*="select__control"] {
      @extend .input;
    }

    [class*="select__menu"] {
      @extend .input;
      border: none;
    }

    [class*="select__menu-list"] {
      border-bottom: 1px solid black;
    }

    [class*="separator"],
    [class*="clear-indicator"] {
      display: none;
    }

    [class*="dropdown-indicator"] {
      background-image: url("/assets/chevronDown.svg");
      background-repeat: no-repeat;
      background-position: center;
      margin: 8px;
      > svg {
        display: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .selectMulti {
    @extend .select;
    position: absolute;
    width: 94.5%;

    [class*="value-container"] {
      [class*="multi-value"] {
        background-color: white;
        height: 1.875rem;
        align-items: center;

        [class*="label"] {
          font-size: 0.875rem;
          height: 1rem;
          padding-top: 0;
          padding-bottom: 0;
          overflow: visible;
        }

        [class*="remove"] {
          padding-right: 0;
          align-items: flex-start;
        }
      }
    }
  }
}

.table {
    .table-header {
        background-color: $lightbg;
    }

    tr.translation-bloc {
        border-bottom: 1px solid $tableBorder;
        border-top: 1px solid $tableBorder;
        height: 3.125rem;
    }

    th:not(.language-name-cell),
    td:not(.language-name-cell) {
        text-align: center;
        vertical-align: middle;
    }

    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        border-radius: 0;
    }
        
    .form-switch input[type="checkbox"] {
        width: 32px;
        height: 16px;
        border-radius: 2em;
    }

    .btn-row {
        position: relative;
        height: 0;

        td {
            position: absolute;
            right: 10px;
            bottom: 10px;
        }
    }

    td,
    .no-border-row,
    .no-border-row + tr:not(.btn-row),
    .language-name-cell {
        border-bottom: 0;
    }

    .language-name-cell {
        padding-bottom: 0;
        vertical-align: bottom;
    }
}

.chevron {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../assets/chevronDown.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.pagination {
  justify-content: flex-end;

  li {
    margin: 0 5px;

    a,
    span {
      border-radius: 0 !important;
      width: 2rem;
      height: 2rem;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      font-size: 0.75rem;
    }

    &[class*="prev"] span,
    &[class*="next"] span {
      display: none;
    }

    &[class*="prev"],
    &[class*="next"] {
      width: 2rem !important;
      height: 2rem !important;
    }

    &[class*="prev"] {
      a::before {
        @extend .chevron;
        transform: rotate(90deg);
      }
    }

    &[class*="next"] {
      a::before {
        @extend .chevron;
        transform: rotate(-90deg);
      }
    }

    &[class*="active"] span {
      background-color: black;
    }
  }
}

.search-form {
  width: 432px;
  position: relative;
  display: flex;
  height: 42px;

  .search-icon {
    height: 42px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 40px 0 0 40px;
  }

  input {
    border-radius: 0 40px 40px 0;
    border: 0;
  }
}

tr.translation-bloc-expanded {
  border-bottom: 0;
}

.translation-edit-container {
  display: flex;
  gap: 50px;
  justify-content: space-between;

  span:first-child {
    width: 4rem;
    margin: auto 0;
  }
}
