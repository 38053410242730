// general
$black: #000000;
$lightblue: #d0e1f0;
$darkgrey: #1b1d29;
$grey: #373f41;
$lightgrey: #6d6c6c;
$local_error: #fb4e78;
$error_text: rgb(220, 53, 69);
$accent_light: #d3ece4;
$grey_light: #93abb2;
$lightbg: #f3f3f3;

// rara
$rara_primary: #090688;
$rara_primary_text: #ffffff;
$rara_primary_hover: #2926aa;
$rara_secondary: #d0e1f0;
$rara_secondary_text: #1b1d28;
$rara_secondary_hover: #c0d1e0;
$rara_light_bg: #f3f3f3;
$rara_accent: #c2dfd6;
$rara_alert_bg: #f4d5a4;
$primary: #090688;
$primary_text: #ffffff;
$primary_hover: #2926aa;
$secondary: #d0e1f0;
$secondary_text: #1b1d28;
$secondary_hover: #c0d1e0;
$accent: #d3ece4;
$tableBorder: #f5f5f5;
$green_hover: #0c847c;
