@import "../../../styles/colors";

.account-info-options {
    box-shadow: 0 8px 16px rgb(0 0 0 / 12%);
    background-color: #fff;
    margin-top: 3.5rem;

    .account-icon {
        padding-left: 16px;
        margin-top: 0px !important;
    }

    .info-option {
        padding-left: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .info-option:hover {
        color: $green_hover;
    }
}

.account-info {
    cursor: pointer;
    .user-name {
        //font-family: 'Inter';
        display: flex;
        color: $darkgrey;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        padding-bottom: 2px;
        margin-top: 5px;
        cursor: pointer;
    }

    .account-role {
        //font-family: 'Inter';
        display: flex;
        color: $lightgrey;
        font-weight: 400;
        font-size: 12px;
        letter-spacing: -0.015em;
        line-height: 16px;
        padding-top: 2px;
    }

    .account-icon {
        padding-left: 16px;
        margin-top: 5px;
    }
}