@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "colors";

@import "~bootstrap/scss/bootstrap";

.page-content {
  padding-bottom: 60px;
  font-family: "Inter", sans-serif;
}

.navbar {
  height: 80px;

  @media screen and (min-width: 992px) {
    height: 100px;
  }

  .navbar-brand img {
    height: 42px;

    @media screen and (min-width: 992px) {
      height: 50px;
    }
  }

  .lang-menu {
    font-size: 15px;
    font-weight: 400;
    color: $black;

    .nav-link {
      &:link,
      &:visited,
      &:hover {
        color: $black;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-menu {
      right: 0;
      left: auto;
    }
  }

  .cur-lang {
    font-size: 15px;
    font-weight: 400;
    color: $black;
  }
}

.alert {
  &.page-alert {
    border-radius: 0;

    svg {
      vertical-align: -10%;
      margin-right: 8px;
    }
  }

  &.alert-info {
    background-color: $lightblue;
    border-color: $lightblue;
    color: $darkgrey;
    line-height: 20px;
    padding-top: 22px;
    padding-bottom: 22px;
    border-width: 0;

    @media screen and (min-width: 992px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.btn {
  padding: 11px 22px;
  //border-width: 0;
  //font-weight: 500;
  border-radius: 60px;
}

.btn-collapse {
  height: 40px;
  width: 40px;
  background-color: $secondary;
  color: $secondary_text;
  padding: 10px;

  svg {
    vertical-align: 0px;
  }

  &:hover {
    background-color: $secondary_hover;
  }
}

.theme-rara {
  .btn {
    padding: 8px 24px;
    border-width: 0;
    font-weight: 500;
    border-radius: 20px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }

  .btn-primary {
    background-color: $rara_primary;
    color: $rara_primary_text;

    &:hover {
      background-color: $rara_primary_hover;
    }
  }

  .btn-secondary {
    background-color: $rara_secondary;
    color: $rara_secondary_text;

    &:hover {
      background-color: $rara_secondary_hover;
    }
  }

  .btn-nav {
    background-color: $rara_accent;
    color: $rara_secondary_text;

    &:hover {
      background-color: $rara_secondary_hover;
    }
  }

  .btn-collapse {
    height: 40px;
    width: 40px;
    background-color: $rara_secondary;
    color: $rara_secondary_text;
    padding: 10px;

    svg {
      vertical-align: 0px;
    }

    &:hover {
      background-color: $rara_secondary_hover;
    }
  }

  .btn-icon {
    padding: 0;
  }
}

.page-title {
  background-color: $rara_light_bg;

  .cont {
    padding-top: 46px;
    padding-bottom: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 50px;
    }

    button {
      margin-left: 16px;
    }
  }
}

.page-alert {
  background-color: $rara_alert_bg;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 86px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;

  .cont {
    position: relative;

    .message {
      padding-left: 70px;
      padding-right: 48px;
    }

    .alert-icon {
      position: absolute;
    }

    .alert-close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.form-section {
  .title {
    font-size: 32px;
    letter-spacing: -1px;
    font-weight: 400;
    line-height: 38px;
    padding: 24px 0;
    display: flex;
    align-items: center;

    .required {
      margin-left: 6px;
      color: $local_error;
    }

    .required {
      color: $local_error;
    }

    button {
      margin-right: 20px;
    }
  }

  .sub-title {
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 8px;

    &.pad-left {
      margin-left: 60px;
    }
  }

  .sub-title-actions {
    text-align: right;
    margin-bottom: 8px;
  }

  .form-label {
    font-size: 14px;
    letter-spacing: -0.015em;
    margin-bottom: 7px;
  }

  .form-label-upper {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .form-select,
  .form-control {
    background-color: $rara_light_bg;
    font-size: 14px;
    border-radius: 0;
    border-top: 0 solid #000000;
    border-left: 0 solid #000000;
    border-right: 0 solid #000000;
    border-bottom: 1px solid #000000;
    line-height: 30px;
    padding-bottom: 5px;
  }

  .form-switch {
    .form-check-input {
      height: 20px;
      width: 36px;
      border-color: $grey_light;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 stroke=%27%2393ABB2%27 stroke-width=%270.4%27 fill=%27%23fff%27/%3e%3c/svg%3e");

      &:checked {
        background-color: $accent_light;
        border-color: $accent_light;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23000%27/%3e%3c/svg%3e");
      }
    }
  }

  .form-check-inline {
    margin-right: 32px;
  }

  .btn-float-cont {
    position: relative;

    .btn-float-right {
      position: absolute;
      right: 2px;
      top: 2px;

      .like-btn,
      button {
        font-size: 14px;
        display: inline-block;
        padding: 8px 24px;
        border-radius: 0;
        background-color: white;
        height: 37px;
        line-height: 21px;
        color: #000;

        &:hover {
          color: #000;
        }
      }
    }
  }

  .form-buttons {
    margin-top: 16px;
    margin-bottom: 16px;

    button {
      margin-right: 16px;
    }
  }
}

.del-confirm-popup {
  .message {
    margin-bottom: 16px;
  }

  .buttons {
    text-align: right;
    button {
      margin-left: 8px;
    }
  }
}

.theme-rara .btn,
.theme-mirko .btn {
  &.btn-icon {
    //display: none;
    height: 32px;
    width: 32px;
    padding: 8px;
    line-height: 16px;

    img {
      vertical-align: -15%;
    }
  }
}

.table > :not(caption) {
  .icon-cell {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.theme-rara .btn,
.theme-mirko .btn {
    &.btn-icon {
        //display: none;
        height: 32px;
        width: 32px;
        padding: 8px;
        line-height: 16px;

        img {
            vertical-align: -15%;
        }
    }
}

.table > :not(caption) {
    .icon-cell {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.local-error {
    font-size: 14px;
    color: $error_text;
    margin-top: 3px;

    &.multi-select-fix {
        margin-top: 54px;
    }
}

.table-sticky-header {
    thead {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
    }
}
