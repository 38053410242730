@import "colors";
@import "~bootstrap/scss/bootstrap";

.title-header {
    height: 150px;
    background-color: $lightbg;

    .title-container {
        justify-content: center;
        align-content: center;
        height: 100%;

        .title-row {
            align-content: center;
            height: 100%;
            
            h1 {
                font-size: 50px;
            }
        }

        .title-right {
            @extend .flex-row-reverse;
            @extend .my-auto;
    
            display: flex;
    
            .btn + .btn {
                margin-right: 14px;
            }
        }
    }
}

.wp-nowrap {
    white-space: nowrap;
}